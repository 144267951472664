import { includes, isEqual } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { environment } from '@environments/environment';

import * as AppInitialActions from '@app/store/app.actions';
import * as fromApp from '@app/store/app.reducer';
import { CoreService } from './services/core.service';
import { NavigationEnd, Router } from '@angular/router';
import { SocketService } from './services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ocg-plus-smart-tv';
  public headerFooter!: boolean;

  constructor(
    private store: Store<fromApp.AppState>,
    public core: CoreService,
    private router: Router,
    private socket: SocketService
  ) {

  }

  ngOnInit() {
    this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.headerFooter = (event.url !== '/splash')
      }
    });

    if (includes(navigator.userAgent, 'Maple2012')) {
      this.core.platform = environment.platforms[1].name
      document.body.setAttribute('data-browser', 'es5');
      (document.querySelector('.app-container') as HTMLDivElement).setAttribute('style', 'zoom=65%;');
    } else if(includes(navigator.userAgent, 'Tizen')) {
      this.core.platform = environment.platforms[2].name
    } else if(includes(navigator.userAgent, ';LGE')){
      this.core.platform = environment.platforms[3].name
    } else if(includes(navigator.userAgent, 'TPM181HE') || includes(navigator.userAgent, 'OCG Build Philips')){
      this.core.platform = environment.platforms[4].name
    } else if(includes(navigator.userAgent, 'OCG Build')){
      this.core.platform = environment.platforms[5].name
    } else {
      this.core.platform = environment.platforms[0].name
      //document.body.setAttribute('data-browser', 'es5');
    }

    this.store.dispatch(
      new AppInitialActions.SetPlatform(this.core.platform)
    );

    if (!environment.production) {
      this.core.debugWebSocket().then(con => {
        if (con)
          this.core.platformInitialize();
      })
    } else {
      this.core.platformInitialize();
    }
   
    

    this.core.SN.init(); //* Init SpatialNavigation
    if (!isEqual(environment.protocol, 'http://'))
      this.socket.initSocket(); //* OCG Events Broadcasting
  }
}
