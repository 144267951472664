import { Profile } from '@app/models/profile';
import { Room } from '@app/models/room';
import { RoomChannelSettings } from '@app/models/roomchannelsettings';
import { RoomPushMessages } from '@app/models/roompushmessages';
import { TVBoxInfo } from '@app/models/tvboxinfo';
import { TVSettings } from '@app/models/tvsettings';
import { WelcomeMsg } from '@app/models/welcomemsg';
import { SET_CHROMECAST_CONTENT } from '@app/pages/tv-and-entertainment/store/tv.actions';
import { Action } from '@ngrx/store';
import { Reset } from '@ngrx/store-devtools/src/actions';


export const SET_PLATFORM = '[Initial App] Set Platform';
export const SET_PLATFORM_VALUES = '[Initial App] Set Platform Values';
export const SET_TV_INPUTS = '[Initial App] Set TV Inputs';
export const SET_TV_CHANNELS = '[Initial App] Set TV Channels';
export const CHECK_IF_TV_EXISTS = '[Initial App] Check If TV exists';
export const SET_TV_UNIQUE_ID = '[Initial App] Set TV Unique Id';
export const GET_TVBOX_INFO = '[Initial App] Get TV Box Info';
export const SET_TVBOX_INFO = '[Initial App] Set TV Box Info';
export const GET_USER_INFO = '[Initial App] Get User Info';
export const SET_USER_INFO = '[Initial App] Set User Info';
export const GET_ROOM_PUSH = '[Initial App] Get Room Push';
export const SET_ROOM_PUSH = '[Initial App] Set Room Push';
export const GET_ROOM_CHANNEL_SETTINGS = '[Initial App] Get Room Channel Settings';
export const SET_ROOM_CHANNEL_SETTINGS = '[Initial App] Set Room Channel Settings';
export const GET_TV_SETTINGS = '[Initial App] Get TV Settings';
export const SET_TV_SETTINGS = '[Initial App] Set TV Settings';
export const GET_LAST_LOGO = '[Initial App] Get Last Logo';
export const SET_LAST_LOGO = '[Initial App] Set Last Logo';
export const GET_LANGUAGE = '[Initial App] Get Language';
export const SET_LANGUAGE = '[Initial App] Set Language';
export const DO_REBOOT = '[Initial App] Do A Reboot';
export const SET_TV_APPLICATIONS = '[Initial App] Set TV Applications';
export const SET_OFFLINE_MODE = '[Initial App] Set Offline Mode';
export const SET_HOTSPOT = '[Initial App] Set Hotspot';
export const INCREMENT_UNREADED_MESSAGES = '[Initial App] Increase Unreaded Messages';
export const UNREADED_MESSAGES_HAS_IMPORTANCE = '[Initial App] Unreaded Messages Has Importance At Least Once';
export const GET_NAVIGATION_MENU = '[Initial App] Get Navigation Menu';
export const SET_NAVIGATION_MENU = '[Initial App] Set Navigation Menu';
export const SET_HAS_CHROMECAST = '[Initial App] Set Chromecast enabled';
export const DO_UPDATE_STATUS = '[Initial App] Update TV IP';
export const ISONLINE = '[Initial App] Is online device';
export const SET_CONCIERGE_NOTIFICATIONS = '[Initial App] Set Concierge Notifications'
export const GET_CONCIERGE_NOTIFICATIONS = '[Initial App] Get Concierge Notifications'
export const SET_HOUSEKEEPING_SETTINGS = '[Initial App] Set Housekeeping settings'
export const GET_HOUSEKEEPING_SETTINGS = '[Initial App] Get Housekeeping settings'
export const SET_DO_NOT_DISTURB = '[Initial App] Set Do not Disturb'
export const SET_CLEAN_MY_ROOM = '[Initial App] Set Clean my room'
export const ADD_IFRAME_STEP = '[Initial App] Add iframe step'
export const SET_IFRAME_BACK = '[Initial App] Set Iframe Back';
export const RESET_IFRAME_STEP = '[Initial App] Reset iframe step'


export class SetPlatform implements Action {
  readonly type = SET_PLATFORM;

  constructor(public payload: string) {}
}
export class SetPlatformValues implements Action {
  readonly type = SET_PLATFORM_VALUES;

  constructor(public payload: any) {}
}

export class SetTVInputs implements Action {
  readonly type = SET_TV_INPUTS;

  constructor(public payload: any) {}
}
export class SetTVChannels implements Action {
  readonly type = SET_TV_CHANNELS;

  constructor(public payload: any) {}
}
export class CheckIfTvExists implements Action {
  readonly type = CHECK_IF_TV_EXISTS;
}
export class SetUniqueId implements Action {
  readonly type = SET_TV_UNIQUE_ID;

  constructor(public payload: string) {}
}
export class GetTvBoxInfo implements Action {
  readonly type = GET_TVBOX_INFO;

  constructor(public payload: any) {}
}
export class SetTvBoxInfo implements Action {
  readonly type = SET_TVBOX_INFO;

  constructor(public payload: TVBoxInfo) {}
}
export class GetUserInfo implements Action {
  readonly type = GET_USER_INFO;
}
export class SetUserInfo implements Action {
  readonly type = SET_USER_INFO;

  constructor(public payload: { profile:Profile, room:Room, welcomemsg:WelcomeMsg[] }) {}
}
export class GetRoomPush implements Action {
  readonly type = GET_ROOM_PUSH;
}
export class SetRoomPush implements Action {
  readonly type = SET_ROOM_PUSH;

  constructor(public payload: RoomPushMessages[] ) {}
}
export class GetRoomChannelSettings implements Action {
  readonly type = GET_ROOM_CHANNEL_SETTINGS;
}
export class SetRoomChannelSettings implements Action {
  readonly type = SET_ROOM_CHANNEL_SETTINGS;

  constructor(public payload: RoomChannelSettings ) {}
}
export class GetTvSettings implements Action {
  readonly type = GET_TV_SETTINGS;
}
export class SetTvSettings implements Action {
  readonly type = SET_TV_SETTINGS;

  constructor(public payload: TVSettings ) {}
}
export class GetLastLogo implements Action {
  readonly type = GET_LAST_LOGO;
}
export class SetLastLogo implements Action {
  readonly type = SET_LAST_LOGO;

  constructor(public payload: string ) {}
}
export class GetLanguage implements Action {
  readonly type = GET_LANGUAGE;
}

export class SetLanguage implements Action {
  readonly type = SET_LANGUAGE;
  constructor(public payload:string){}
}

export class DoReboot implements Action {
  readonly type = DO_REBOOT;
}
export class SetTvApplictions implements Action {
  readonly type = SET_TV_APPLICATIONS;

  constructor(public payload: Array<any> ) {}
}
export class SetOfflineMode implements Action {
  readonly type = SET_OFFLINE_MODE;

  constructor(public payload: any) {}
}
export class SetHotspot implements Action {
  readonly type = SET_HOTSPOT;

  constructor(public payload: { ssid: string, pass:string }) {}
}
export class IncreamentUnreadedMessages implements Action {
  readonly type = INCREMENT_UNREADED_MESSAGES;

  constructor(public payload: number) {}
}
export class UnreadedMessagesHasImportance implements Action {
  readonly type = UNREADED_MESSAGES_HAS_IMPORTANCE;

  constructor(public payload: boolean) {}
}
export class GetNavigationMenu implements Action {
  readonly type = GET_NAVIGATION_MENU;
}
export class SetNavigationMenu implements Action {
  readonly type = SET_NAVIGATION_MENU;

  constructor(public payload: Array<any>) {}
}

export class SetChromecastEnable implements Action {
  readonly type = SET_HAS_CHROMECAST;

  constructor(public payload: boolean) {}
}
export class DoUpdateIp implements Action {
  readonly type = DO_UPDATE_STATUS;
}
export class IsOnline implements Action {
  readonly type = ISONLINE;
}

export class GetConciergeNotifications implements Action {
  readonly type = GET_CONCIERGE_NOTIFICATIONS;
}
export class SetConciergeNotifications implements Action {
  readonly type = SET_CONCIERGE_NOTIFICATIONS;

  constructor(public payload: number) {}
}

export class GetHouseKeepingSettings implements Action {
  readonly type = GET_HOUSEKEEPING_SETTINGS;
}
export class SetHouseKeepingSettings implements Action {
  readonly type = SET_HOUSEKEEPING_SETTINGS;
  constructor(public payload: any) {}
}

export class SetDoNotDisturb implements Action {
  readonly type = SET_DO_NOT_DISTURB;
  constructor(public payload: any) {}
}

export class SetCleanMyRoom implements Action {
  readonly type = SET_CLEAN_MY_ROOM;
  constructor(public payload: any) {}
}

export class SetIframeBack implements Action{
  readonly type = SET_IFRAME_BACK; 
  constructor(public payload:boolean){}
}

export class AddIframeStep implements Action{
  readonly type = ADD_IFRAME_STEP;
  constructor(public payload:number){};
}

export class ResetIframeStep implements Action{
  readonly type = RESET_IFRAME_STEP;
}


export type AppStoreActions =
  | SetPlatformValues
  | SetPlatform
  | SetUniqueId
  | CheckIfTvExists
  | GetTvBoxInfo
  | SetTvBoxInfo
  | SetTVChannels
  | GetUserInfo
  | SetUserInfo
  | GetRoomPush
  | SetRoomPush
  | GetRoomChannelSettings
  | SetRoomChannelSettings
  | GetTvSettings
  | SetTvSettings
  | GetLastLogo
  | SetLastLogo
  | GetLanguage
  | SetLanguage
  | DoReboot
  | SetTvApplictions
  | SetOfflineMode
  | SetHotspot
  | IncreamentUnreadedMessages
  | UnreadedMessagesHasImportance
  | SetNavigationMenu
  | GetNavigationMenu
  | SetChromecastEnable
  | DoUpdateIp
  | IsOnline
  | SetTVInputs
  | GetConciergeNotifications
  | SetConciergeNotifications
  | GetHouseKeepingSettings
  | SetHouseKeepingSettings
  | SetDoNotDisturb
  | SetCleanMyRoom
  | SetIframeBack
  | AddIframeStep
  | ResetIframeStep;
