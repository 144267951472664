import { ActionReducerMap } from '@ngrx/store';

import * as AppInitialActions from './app.actions';
import * as fromSplashStore from '@app/pages/splash/store/splash.reducer';
import * as fromHomeStore from '@app/pages/home/store/home.reducer';
import * as fromContentListStore from '@app/pages/category/store/category.reducer';
import * as fromTVStore from '@app/pages/tv-and-entertainment/store/tv.reducer';
import * as fromSettingsStore from '@app/pages/settings/store/settings.reducer';
import * as fromInformationStore from '@app/pages/information/store/information.reducer';
import * as fromHotelServices from '@app/pages/hotel_services/store/hotel-services.reducer';
import * as fromGuestStore from '@app/pages/guest/store/guest.reducer';
import * as fromSmartRoomStore from '@app/pages/smart-room/store/smart-room.reducer';
import * as fromReservationsStore from '@app/pages/reservations/store/reservations.reducer';

import { Profile } from '@app/models/profile';
import { Room } from '@app/models/room';
import { WelcomeMsg } from '@app/models/welcomemsg';
import { TVBoxInfo } from '@app/models/tvboxinfo';
import { RoomPushMessages } from '@app/models/roompushmessages';
import { RoomChannelSettings } from '@app/models/roomchannelsettings';
import { TVSettings } from '@app/models/tvsettings';

export interface AppState {
  guestStore: fromGuestStore.State,
  hotelServicesStore: fromHotelServices.State,
  informationStore: fromInformationStore.State,
  settingsStore: fromSettingsStore.State;
  tvStore: fromTVStore.State;
  splashStore: fromSplashStore.State;
  contentListStore: fromContentListStore.State;
  homeStore: fromHomeStore.State;
  smartRoomStore: fromSmartRoomStore.State,
  reservationsStore: fromReservationsStore.State,
  appStore: State;
}

export interface State {
  platform: string;
  deviceToken: string,
  IPv4: string,
  IPv6: string,
  deviceOnline: boolean,
  MAC: string,
  unique_id: string,
  SSID: string,
  wifiPass: string,
  startupInputType:string,
  startupInputIndex:number,
  tvbox_info: TVBoxInfo,
  profile:Profile,
  room:Room,
  welcomemsg:WelcomeMsg[],
  roomPushMessages: RoomPushMessages[];
  roomChannelSettings: RoomChannelSettings
  tvSettings: TVSettings;
  lastLogo: any;
  appications: Array<any>;
  unReadedMessages: Number;
  hasImportance: boolean;
  hasChromecast: boolean;
  navigationMenu: Array<any>;
  conciergeNotifications: number;
  doNotDisturb: number;
  cleanMyRoom: number;
  iframeBack:boolean;
  iframeStep:number;
  tvChannels:any[];
}

const initialState: State = {
  platform: '',
  deviceToken: '',
  IPv4: '',
  IPv6: '',
  deviceOnline: false,
  MAC: '',
  unique_id: '',
  SSID: '',
  wifiPass: '',
  startupInputType: '',
  startupInputIndex: -1,
  tvbox_info: new TVBoxInfo,
  profile: new Profile,
  room: new Room,
  welcomemsg: [],
  roomPushMessages: [],
  roomChannelSettings: new RoomChannelSettings,
  tvSettings: new TVSettings,
  lastLogo: null,
  appications: [],
  unReadedMessages: 0,
  hasImportance: false,
  hasChromecast: false,
  navigationMenu: [],
  conciergeNotifications: 0,
  doNotDisturb: -1,
  cleanMyRoom: -1,
  iframeBack:false,
  iframeStep:0,
  tvChannels:[]
}

export function appStore(
  state: State = initialState,
  action: AppInitialActions.AppStoreActions
): State {
  switch (action.type) {
    case AppInitialActions.SET_PLATFORM:
      return {
        ...state,
        platform: action.payload
      };
    case AppInitialActions.SET_PLATFORM_VALUES:
      return {
        ...state,
        deviceToken: action.payload.deviceToken,
        IPv4: action.payload.IPv4,
        IPv6: action.payload.IPv6,
        deviceOnline: action.payload.deviceOnline,
        MAC: action.payload.MAC
      };
      case AppInitialActions.SET_TV_INPUTS:
        return {
          ...state,
          startupInputType: action.payload.startupInputType,
          startupInputIndex: action.payload.startupInputIndex
        };
    case AppInitialActions.SET_TV_CHANNELS:
      return {
        ...state,
        tvChannels: action.payload
      };
    case AppInitialActions.SET_TV_UNIQUE_ID:
      return {
        ...state,
        unique_id: action.payload
      }
    case AppInitialActions.SET_TVBOX_INFO:
      return {
        ...state,
        tvbox_info: action.payload
      }
    case AppInitialActions.SET_USER_INFO:
      return {
        ...state,
        profile: action.payload.profile,
        room: action.payload.room,
        welcomemsg: action.payload.welcomemsg,
      }
    case AppInitialActions.SET_LANGUAGE:
      return{
        ...state,
        room:{...state.room,tv_settings_language:action.payload},
      }
    case AppInitialActions.SET_ROOM_PUSH:
      return {
        ...state,
        roomPushMessages: action.payload
      }
    case AppInitialActions.SET_ROOM_CHANNEL_SETTINGS:
      return {
        ...state,
        roomChannelSettings: action.payload
      }
    case AppInitialActions.SET_TV_SETTINGS:
      return {
        ...state,
        tvSettings: action.payload
      }
    case AppInitialActions.SET_LAST_LOGO:
      return {
        ...state,
        lastLogo: action.payload
      }
    case AppInitialActions.SET_TV_APPLICATIONS:
      return {
        ...state,
        appications: action.payload
      }
    case AppInitialActions.SET_HOTSPOT:
      return {
        ...state,
        SSID: action.payload.ssid,
        wifiPass: action.payload.pass
      }
    case AppInitialActions.INCREMENT_UNREADED_MESSAGES:
      return {
        ...state,
        unReadedMessages: action.payload
      }
    case AppInitialActions.UNREADED_MESSAGES_HAS_IMPORTANCE:
      return {
        ...state,
        hasImportance: action.payload
      }
    case AppInitialActions.SET_NAVIGATION_MENU:
      return {
        ...state,
        navigationMenu: action.payload
      }
    case AppInitialActions.SET_HAS_CHROMECAST:
      return {
        ...state,
        hasChromecast: action.payload
      }
      case AppInitialActions.SET_CONCIERGE_NOTIFICATIONS:
        return {
          ...state,
          conciergeNotifications: action.payload
        }
      case AppInitialActions.SET_HOUSEKEEPING_SETTINGS:
        return {
          ...state,
          doNotDisturb: action.payload.doNotDisturb,
          cleanMyRoom: action.payload.cleanMyRoom
        }
      case AppInitialActions.SET_DO_NOT_DISTURB:
        return {
          ...state,
          doNotDisturb: action.payload
        }
      case AppInitialActions.SET_CLEAN_MY_ROOM:
        return {
          ...state,
          cleanMyRoom: action.payload
        }
      case AppInitialActions.ADD_IFRAME_STEP:
        return{
          ...state,
          iframeStep:state.iframeStep+action.payload
        }
      case AppInitialActions.SET_IFRAME_BACK:
        return{
          ...state,
          iframeBack:action.payload
        }
      case AppInitialActions.RESET_IFRAME_STEP:
        return{
          ...state,
          iframeStep:0
        }
      case AppInitialActions.SET_OFFLINE_MODE:
        return{
          ...state,
          deviceOnline:false
        }
    default:
      return state;
  }
}

export const appReducer: ActionReducerMap<AppState, any> = {
  guestStore: fromGuestStore.guestReducer,
  hotelServicesStore: fromHotelServices.hotelServicesReducer,
  informationStore: fromInformationStore.InformationReducer,
  settingsStore: fromSettingsStore.settingsReducer,
  tvStore: fromTVStore.tvReducer,
  splashStore: fromSplashStore.splashReducer,
  contentListStore: fromContentListStore.contentListReducer,
  homeStore: fromHomeStore.homeReducer,
  smartRoomStore: fromSmartRoomStore.SmartRoomReducer,
  reservationsStore: fromReservationsStore.reservationsReducer,
  appStore: appStore
}
