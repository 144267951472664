import { Inject, Injectable } from '@angular/core';
import * as socketIo from 'socket.io-client';
import { CoreService } from './core.service';

import { environment } from '@environments/environment';
import * as InitialAppActions from '@app/store/app.actions';
import { Store } from '@ngrx/store';
import * as fromApp from '@app/store/app.reducer';
import { filter, take } from 'rxjs/operators';
import { ToastService } from '@app/components/toast/toast.service';
import { ToastComponent } from '@app/components/toast/toast.component';
import { DOCUMENT } from '@angular/common';
import { AlarmService } from '@app/components/alarm/alarm.service';
import { AlarmComponent } from '@app/components/alarm/alarm.component';
import { LGService } from './lg.service';
import * as _ from 'lodash';
import { isNull } from 'lodash';
import { TizenService } from './tizen.service';

var TIMEOUTLIMIT = 200000;

@Injectable({providedIn: 'root'})
export class SocketService {
    socketChannel:string
    private socket!: SocketIOClient.Socket;
    private SERVER_URL: string;
    private tvbox_id!:number;
    private sockTimeout:number = 0;
    private corporate_id!:number;
    private alarmSubject:boolean = false;
    private platform:string = '';
    private macAddress:string = '';

    constructor(
      private core: CoreService,
      private lgService: LGService,
      private tizenService: TizenService,
      private store: Store<fromApp.AppState>,
      private toastService: ToastService,
      private alarmServive: AlarmService,
      @Inject(DOCUMENT) private document: Document
    ) {
        this.SERVER_URL = environment.protocol+environment.domain+'';
        this.socketChannel = environment.domain+'_smartTv';
    }

    public initSocket(): void {
        console.info('opening socket: '+this.SERVER_URL);

        try{
            this.socket = socketIo(this.SERVER_URL);
            this.socket.on('connect', () => {
                console.info('Connected to: '+this.SERVER_URL);
                console.info('Socket ID: '+this.socket.id);
                this.socket.emit('joinRoom', environment.domain);

                this.sockTimeout = 0;
                this.socket.on(this.socketChannel, (datarcvd: any)=>{
                    //console.log(datarcvd);
                    this.onChannelEvent(JSON.parse(datarcvd));
                });
            });
            this.socket.on('connect_error', (e: any)=>{
                console.info('Could not connect to socket '+ e.toString());
                this.socket.removeAllListeners();
                this.socket.close();
                if(this.sockTimeout <= TIMEOUTLIMIT){
                    this.sockTimeout += 10000;
                    console.info('retrying to connect in '+this.sockTimeout+'ms');
                    setTimeout(() => {
                        this.initSocket();
                    }, this.sockTimeout);
                }
            });
            this.socket.on('connect_timeout', (e: any)=>{
                console.info('Socket connection timed out'+ e.toString());
                if(this.sockTimeout <= TIMEOUTLIMIT){
                  this.socket.removeAllListeners();
                  this.socket.close();
                  this.sockTimeout += 10000;
                  console.info('retrying to connect in '+this.sockTimeout+'ms');
                  setTimeout(() => {
                      this.initSocket();
                  }, this.sockTimeout);
              }
            });
        }catch(e){
            console.info(e);
        }
    }

    public onChannelEvent(datarcvd:any){
      console.info(JSON.stringify(datarcvd));
      this.store.select('appStore').pipe(
        filter(state => !!state.tvbox_info.tvbox_id && !!state.tvbox_info.corporate),
        take(1)
        ).subscribe(state => {
        this.corporate_id = state.tvbox_info.corporate;
        this.tvbox_id = state.tvbox_info.tvbox_id;
        this.platform = state.platform;
        this.macAddress = (state.MAC as string).toLowerCase();
      })

      if (datarcvd.tvbox_id == this.tvbox_id || datarcvd.device_id == this.tvbox_id) {
        if (datarcvd.action == 'notification'){
          //console.log(datarcvd);
          //* Received new notification & Generate Toast component
          let toast = this.toastService.createComponent(ToastComponent);
          (<ToastComponent>toast.instance).id = 'toastControl';
          (<ToastComponent>toast.instance).title =  datarcvd.importance == "0" ? this.core.translate.instant("Information") : this.core.translate.instant("Important");
          (<ToastComponent>toast.instance).content = this.core.translate.instant('New message received');
          (<ToastComponent>toast.instance).type = datarcvd.importance == "0" ? 'info' : 'alert';

          toast.hostView.detectChanges();
          const { nativeElement } = toast.location;
          this.document.body.appendChild(nativeElement);

          (<ToastComponent>toast.instance).toggle();

              this.store.dispatch(new InitialAppActions.GetRoomPush());
            }
            else if(datarcvd.action == 'reboot'){
                this.core.rebootState();
            }else if(datarcvd.action == 'channel'){
                this.tizenService.getTVChannels(datarcvd.action_id);          
            }
        }
    }
}
