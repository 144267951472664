import { Action } from '@ngrx/store';

export const GET_CONTENT_LIST = '[Content List Page] Get Content List';
export const SET_CONTENT_LIST = '[Content List Page] Set Content List';
export const RESET_CONTENT_LIST = '[Content List Page] Reset Content List';
export const SET_CURRENT_CONTENT_ID = '[Content List Page] Set Current Content Id';

export class GetContentList implements Action {
  readonly type = GET_CONTENT_LIST;

  constructor(public payload: string){};
}
export class SetContentList implements Action {
  readonly type = SET_CONTENT_LIST;

  constructor(public payload: any ) {}
}
export class ResetContentList implements Action {
  readonly type = RESET_CONTENT_LIST;
}

export class SetCurrentContentId implements Action{
  readonly type = SET_CURRENT_CONTENT_ID;
  constructor(public payload: any ) {}
}

export type ContentListActions =
  | GetContentList
  | SetContentList
  | ResetContentList
  | SetCurrentContentId;

