<div class="footer" id="footer" [style.background-color]="!showFooter ? 'transparent' : ''">
  <div class="row footer-row"
    [@footerAnimation]="showFooter.home? 'shown': showFooter.inside ? 'shownInside' : 'hidden'"
    (@footerAnimation.done)="animDone($event)">
    <!-- new footer/menu-bar ------------------------------ -->
    <ng-container>
      <div class="col-1 new-footer-category focusable" tabindex="-1" *ngFor="let item of footerMenu; let i = index"
        (keydown.enter)="onCategoryClicked(item)" (click)="onCategoryClicked(item)" (focus)="onFocus($event, i)" (blur)="onBlur($event, i)" [style.background]="item.backgroundColor || defaultMenu[i].backgroundColor"  [style.border]="(item.borderColor)?'3px solid '+item.borderColor:'0px'" >
        <sprite-icon icon="{{item.icon}}"></sprite-icon>
        <p>{{ (item | langFilter).title | translate }}</p>
        <span class="badge bounce_item"
          [ngClass]="{'badge-warning': !(state$ | async).hasImportance, 'badge-danger': (state$ | async).hasImportance }"
          *ngIf="item.title == 'Guest' && ((state$ | async).unReadedMessages > 0 || (state$ | async).conciergeNotifications > 0)">
          {{ (state$ | async).unReadedMessages + (state$ | async).conciergeNotifications }}
        </span>
      </div>
    </ng-container>
    <!-- ------------------------------------------------- -->
  </div>
  <!-- buttons row --------------------------------------- -->
  <div class="row btn-row align-items-center justify-content-between"
    [@buttonsAnimation]="showFooter.home ? 'hidden': 'shown'">
    <div class="col-1">
      <button id="back" class="btn btn-danger" tabindex="-1" (keydown)="navigateBack($event)" (click)="navigateBackClick($event)">{{ 'BACK' | translate
        }}</button>
    </div>
    <div class="col-1">
      <button id="showMenu" class="btn btn-danger" tabindex="-1" (keydown.enter)="navigateToHome()" (click)="navigateToHome()">{{ 'MENU'| translate}}</button>
    </div>
  </div>
</div>
