import { Content } from '@app/models/content';
import * as ContentListActions from './category.actions';

export interface State {
  contentList: Array<any>,
  previousSelectedId:any | null}

const initialState: State = {
  contentList : [],
  previousSelectedId:null
}



export function contentListReducer(
  state: State = initialState,
  action: ContentListActions.ContentListActions
): State {
  switch (action.type) {
    case ContentListActions.SET_CONTENT_LIST:
      return {
        ...state,
        contentList: action.payload
      }
    case ContentListActions.RESET_CONTENT_LIST:
      return initialState;
    case ContentListActions.SET_CURRENT_CONTENT_ID:
      return {
        ...state,
        previousSelectedId:action.payload
      }
    default:
      return state;
  }
}
