import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CoreService } from '@app/services/core.service';
import { TizenService } from '@app/services/tizen.service';
import { environment } from '@environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoComponent implements OnInit, AfterViewInit {
  @Input() poster!: string;
  @Input() videoUrl!: string;
  @Input() loop: boolean = false;
  @Input() muted: boolean = true;
  public videoElement!: HTMLVideoElement
  public tizenVideoElement!: HTMLObjectElement;

  @ViewChild('video') videoWrap!: ElementRef;

  //public tizenUsefulKeys = (tizenKeys as any).tizenUsefulKeys;
  constructor(
    private core: CoreService,
    public _sanitizer: DomSanitizer,
    public tizenService:TizenService
  ) { }

  ngOnInit(): void {

  }

  
  samsungRestartistener(){
    let win = (window as any);
    win.webapis.avplay.stop();
    console.info('videos listener')
    //if (this.loop) {
      win.webapis.avplay.prepareAsync(
        () => {

          win.webapis.avplay.play();
          win.webapis.avplay.setDisplayRect(0, 0, 1920, 1080);
          console.info('videos prepared')
        },
        (err: any) => {
          win.webapis.avplay.stop();
        }
      );
      win.webapi.avplay.setListener({
        onstreamcompleted: this.samsungRestartistener
      });
    //}
  }

  ngAfterViewInit(): void {
  
    if (this.core.platform === environment.platforms[1].name) {
      setTimeout(() => {
        let win = (window as any);
        
        win.tizen.tvinputdevice.registerKeyBatch(this.tizenService.tizenUsefulKeys);
        console.info('videos problem --------')
        this.tizenVideoElement = document.createElement('object');
        this.tizenVideoElement.setAttribute('style', 'width:1920px;height:1080px');
        this.tizenVideoElement.type = 'application/avplayer';
        this.videoWrap.nativeElement.appendChild(this.tizenVideoElement);
        //win.webapis.avplay.setDisplayRect(0, 0, 1920, 1080);
        win.webapis.avplay.open(this.videoUrl);
        var listener = {          
          onstreamcompleted: function () {
              //console.info("Stream Completed");
              win.webapis.avplay.stop();
              win.webapis.avplay.prepare();
              win.webapis.avplay.play();
          },
          onerror: function (eventType:any) {
              console.info("event type error : " + eventType);
          }
      };
      try{
        win.webapis.avplay.setListener(listener);
      }catch (e){
        console.info('error: '+JSON.stringify(e))
      }
        win.webapis.avplay.prepareAsync(
          () => {           
            win.webapis.avplay.play();
            win.webapis.avplay.setDisplayRect(0, 0, 1920, 1080);
            
          },
          (err: any) => {
            win.webapis.avplay.stop();
          }
        );
       
      }, 1000)
      
    } else {
      this.videoElement = document.createElement('video');
      //alert('not tizen');         
      this.videoElement.setAttribute('type', 'video/mp4');
      //this.videoElement.muted = true;
      this.videoElement.preload = 'auto';
      this.videoElement.id = 'video';
      this.videoElement.setAttribute('playsinline', '');
      this.videoElement.style.background = 'black';
      this.videoWrap.nativeElement.appendChild(this.videoElement);
      this.videoElement.controls = false;
      this.videoElement.loop = false;

      this.videoElement.poster = this.poster;
      this.videoElement.src = this.videoUrl;

      this.videoElement.load();
      setTimeout(() => {
        this.videoElement.play();
      }, 100);


      this.videoElement.addEventListener('ended', () => {
        console.info('Video ended.');
        if (this.loop) {
          this.videoElement.currentTime = 0.01;
          this.videoElement.load();
          this.videoElement.play();
        }
      })
    }
  }
}
