
<div class="ocg-carousel" *ngIf="items.length > 0">
  
  <div class="ocg-carousel-stage" [ngStyle]="carouselWrapperStyle">
    <ul class="ocg-carousel-inner" #carousel>
      <li *ngFor="let item of items;" class="ocg-carousel-item">
        <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
      </li>
    </ul>
  </div>
  <div *ngIf="showControls" style="margin-top: 1em">
    <button (click)="next()" class="btn btn-default">Next</button>
    <button (click)="prev()" class="btn btn-default">Prev</button>
  </div>
</div>

