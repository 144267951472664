import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { ControlService } from '@app/services/control.service';
import { CoreService } from '@app/services/core.service';
import { Location } from '@angular/common';
import { endsWith, includes } from 'lodash';
import { Store } from '@ngrx/store';
import * as fromApp from '@app/store/app.reducer';
import * as AppStoreActions from '@app/store/app.actions';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IframeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() url!: any;
  public iframe!: HTMLIFrameElement;
  private back = document.getElementById('back');
  private showmenu = document.getElementById('showMenu');
  private footer =  document.getElementById('footer');
  private SMlistener: any;
  private BBlistener: any;

  constructor(
    private core: CoreService,
    private control: ControlService,
    private router: Router,
    private _location: Location,
    private _renderer: Renderer2,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.iframe = document.getElementById('iframe') as HTMLIFrameElement;

   

    setTimeout(() => {
      this.SMlistener = this._renderer.listen(
        this.showmenu,
        'keydown',
        this.initFooterMenuNav.bind(this)
      );
      /**
       * * Back Event pre load
       */
      this.BBlistener = this._renderer.listen(
        this.back,
        'keydown',
        this.handleBack.bind(this)
      );
    }, 200);
    this.core.SN.add({
      selector: '#iframe',
      id: 'iframe',
      rememberSource: false,
      restrict: 'self-first',
    });
  }

  initFooterMenuNav(evn: KeyboardEvent){
    // console.log(1);
    if (this.control.OKPressed(evn)) {
      if (
        endsWith(this.router.url, 'room-service') ||
        includes(this.router.url, 'facilities-reservations') ||
        endsWith(this.router.url, 'e-catalogue')
      ) {
        console.info('---- Show frame Menu ----');
        this.core.SN.disable('iframe')
      }
    }
  }

  handleBack(evn: KeyboardEvent) {
    if (this.control.OKPressed(evn)) {
      if (
        endsWith(this.router.url, 'room-service') ||
        endsWith(this.router.url, 'e-catalogue')
      ) {
        console.info('---- back frame event ----');
        this.router.navigate(['/category'], {queryParams: { slug: 'reservations' }, skipLocationChange: true});
        window.history.go(-2);
      } else if (includes(this.router.url, 'facilities-booking')) {
        console.info('---- back frame event ----');
      }
    }
  }

  @HostListener('window:message', ['$event']) onMessage(event: any) {
    //console.log(event);
    if (event.data == 'back_button') {
      console.info('---- in iframe back btn pressed ----');
      window.history.go(-2);
    } else if (event.data == 'return') {
      this.router.navigate(['/home']);
    } else if (event.data == 'showmenu') {
      this.showmenu!.focus();
    } else if (event.data == 'back') {
      this.back!.focus();
    } else if(event.data == 'go_back') {
      console.info('---- back btn change history ----');
      window.history.go(-4);
    } else if(event.data == 'go_back_form_service') {
      console.info('---- back btn change history (service) ----');
      window.history.go(-5);
    } else if(event.data == 'last_step'){
      this.store.dispatch(new AppStoreActions.SetIframeBack(true))
    } else if(event.data == 'restore_last_step'){
      this.store.dispatch(new AppStoreActions.SetIframeBack(false))
    } else if(event.data == 'add_step'){
      this.store.dispatch(new AppStoreActions.AddIframeStep(1))
    } else if(event.data == 'subtract_step'){
      this.store.dispatch(new AppStoreActions.AddIframeStep(-1))
    }
  }

  iframeLoaded() {
    console.info('---- iframe loaded ----');
    this.store.dispatch(new AppStoreActions.ResetIframeStep())
    this.iframe?.focus();
    if (this.core.platform != 'PC') {
      this.iframe.contentWindow?.postMessage('is_tv', '*');
    }
  }

  onIframeFocus(e: FocusEvent) {
    console.info('---- onIframeFocus ----');
    if (includes(this.router.url, 'concierge')) {
      this.iframe.contentWindow?.postMessage('typebox','*');
    } else {
      this.iframe.contentWindow?.postMessage('focusIn', '*');
    }
  }

  ngOnDestroy() {
    this.core.SN.remove('iframe');
    this.BBlistener();
    this.SMlistener();
  }
}
