import * as InformationActions from './information.actions';

export interface State {
  pointsCategories: Array<any>;
  androidQrCode: string;
  iosQrCode: string;
  busInfos: Array<any>;
  txt_forecast: any;
  simpleforecast: any,
  previousSelectedId:any | null
}

const initialState: State = {
  pointsCategories: [],
  androidQrCode: '',
  iosQrCode: '',
  busInfos: [],
  txt_forecast: null,
  simpleforecast: null,
  previousSelectedId:null
};

export function InformationReducer(
  state: State = initialState,
  action: InformationActions.InformationActions
): State {
  switch (action.type) {
    case InformationActions.SET_POI_CATEGORIES_LIST:
      return {
        ...state,
        pointsCategories: action.payload,
      };
    case InformationActions.SET_QRCODES_ANDROID:
      return {
        ...state,
        androidQrCode: action.payload,
      };
    case InformationActions.SET_QRCODES_IOS:
      return {
        ...state,
        iosQrCode: action.payload,
      };
    case InformationActions.SET_BUS_INFOS:
      return {
        ...state,
        busInfos: action.payload,
      }
    case InformationActions.SET_WEATHER_DATA:
      return {
        ...state,
        txt_forecast: action.payload.txt_forecast,
        simpleforecast: action.payload.simpleforecast,
      }
    case InformationActions.SET_CURRENT_CONTENT_ID:
      return {
        ...state,
        previousSelectedId:action.payload
      }
    default:
      return state;
  }
}
